import { FiltersModel } from "@/core/models/filters";

export enum QuestionnaireFilterItem {
  CATEGORY = "category",
  SEARCH = "search",
}

export class QuestionnaireFiltersModel extends FiltersModel {
  public [QuestionnaireFilterItem.CATEGORY]: string | number;

  constructor(query: any) {
    super();
    (this[QuestionnaireFilterItem.CATEGORY] =
      query[QuestionnaireFilterItem.CATEGORY]),
      (this[QuestionnaireFilterItem.SEARCH] = query[QuestionnaireFilterItem.SEARCH]),
      this.initAttrsFromQuery(query);
  }
}
