import { FilterItemModel } from "@/core/models/filter-item";
import { CategoryType } from "@/core/category-type";

function getOptionValue(option: CategoryType | any, value: string) {
  if (value.includes(".")) {
    return value.split(".").reduce((a: any, b: any) => a[b], option);
  } else {
    return option[value];
  }
}

export const prepareFilterOptions = (
  options: Array<CategoryType | any>,
  settings: { value?: string; label?: string } = {}
): Array<FilterItemModel> => {
  const value = settings && settings.value ? settings.value : "id";
  const label = settings && settings.label ? settings.label : "name";
  return options.map(
    (option) =>
      new FilterItemModel(
        getOptionValue(option, value),
        getOptionValue(option, label)
      )
  );
};
