import { SelectOptionModel } from "@/core/models/select-option";
import {normalizeUpperCase} from "@/utils/normalizeUpperCase";

type ParamsType = {
  value: string | number;
};

type OptionsApi = (
  paramValue?: string | number
) => Promise<Array<{ [key1: string]: any }>>;

type SelectOptionSettings = {
  apiTitle: string;
  apiValue: string | number;
  additional: string;
};

export class SelectOptionsFromArray {
  private list: Array<any>;
  private settings: SelectOptionSettings;
  constructor(list: Array<any>, settings?: SelectOptionSettings) {
    this.list = list;
    this.settings = { apiTitle: "name", apiValue: "id", additional: '', ...settings };
  }

  getOptions(): Array<SelectOptionModel> {
    return this.list.map(item => new SelectOptionModel(item[this.settings.apiTitle], item[this.settings.apiValue]));
  }
}

export class SelectOptionsFromApi {
  private api: OptionsApi;
  private params?: ParamsType;
  private settings: SelectOptionSettings;
  constructor(
    api: OptionsApi,
    params?: ParamsType,
    settings?: SelectOptionSettings
  ) {
    this.api = api;
    this.params = params;
    this.settings = { apiTitle: "name", apiValue: "id", additional: '', ...settings };
  }

  getOptions = async (): Promise<Array<SelectOptionModel>> => {
    const response = await this.api(this.params?.value);
    return response.map(
      (item) => new SelectOptionModel(item[this.settings.apiTitle], item[this.settings.apiValue.toString()], item[this.settings.additional])
    );
  };
}

export class SelectOptionFromEnumList {
  private list: Array<string>
  constructor(list: Array<string>) {
    this.list = list;
  }

  getOptions(): Array<SelectOptionModel> {
    return this.list.map(item => new SelectOptionModel(normalizeUpperCase(item), item));
  }
}
