export class FiltersModel {
  [key: string]: any;

  initAttrsFromQuery(query: { [key: string]: string | number }) {
    Object.keys(query).forEach((key) => {
      this[key] = query[key];
    });
  }

  clearFilters() {
    Object.keys(this).forEach((filter) => {
      this[filter] = null;
    });
  }

  get activeFilters() {
    const activeFilters: { [key: string]: string | number } = {};

    Object.keys(this).forEach((filter) => {
      if (Array.isArray(this[filter]) && this[filter].length > 0) {
        activeFilters[filter] = this[filter];
      } else if ((this[filter] && !Array.isArray(this[filter])) || (![null, undefined].includes(this[filter]) && ['false', 'true'].includes(this[filter].toString()))) {
        activeFilters[filter] = this[filter];
      }
    });

    return activeFilters;
  }
}
