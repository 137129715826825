import { bool } from "yup";

export class SelectOptionModel {
  constructor(public label: string, public value: string | number, public additional?: string, public is_global?: boolean) {
    this.label = label;
    this.value = value;
    this.additional = additional;
    this.is_global = is_global || true;
  }
}
