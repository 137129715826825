// EXAMPLES
// TEST -> Test
// NEW_TEST -> New test

export const normalizeUpperCase = (string: string): string => {
  return (
    string.charAt(0).toUpperCase() +
    string.slice(1).replaceAll("_", " ").toLowerCase()
  );
};
